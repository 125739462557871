import { InteractionRequiredAuthError, PublicClientApplication, type AccountInfo } from '@azure/msal-browser';
import type { UserData } from './client';
import { useAppStore } from '@/stores/app';
import { storeToRefs } from 'pinia';
import router from '@/router';

export default class authClient {
    private msalConfig = {
        auth: {
            clientId: '3a9ea044-f3dd-48d5-a4e0-a092ff0568ec',
            authority: 'https://login.microsoftonline.com/organizations',
            redirectUri: 'https://localhost:5173/app/login'
        },
        cache: {
            cacheLocation: ("localStorage" as "localStorage"),
            storeAuthStateInCookie: true
        }
    }
    private $msalInstance = new PublicClientApplication(this.msalConfig);

    public tenant: string | null = null;
    public async GetAllAccounts(): Promise<AccountInfo[] | null> {
        if (this.xApiToken != null)
            return [];
        const accounts = this.$msalInstance.getAllAccounts();
        return accounts;
    }

    public get account(): AccountInfo | null {
        return this.$msalInstance.getActiveAccount() ?? null;
    }

    public set account(account: AccountInfo | null) {
        this.accessToken = null;
        this.accessTokenExpiresOn = null;
        this.tenant = null;
        this.$msalInstance.setActiveAccount(account);
    }

    public userDetails: UserData | null = null;

    public async HandleRedirectResponse() {
        await this.$msalInstance.initialize();

        const authenticationResult = await this.$msalInstance.handleRedirectPromise();
        return authenticationResult?.state;
    }

    public async Initialize(usePopup?: boolean) {
        const respState =  await this.HandleRedirectResponse();
       
        console.log('retrieving user account ...');
        const tempAccount = await this.TryGetAccount();
        if (tempAccount == null) {
            if(usePopup) {
                try {
                    const url = new URL(window.location.href);
                    const params = new URLSearchParams(url.search);
                    const queryParams = Object.fromEntries(params);
                    const ret = await this.$msalInstance.ssoSilent({ scopes: ["User.Read"], loginHint: queryParams["loginHint"] });
                    if(ret.account?.name) {
                        this.account = ret.account;
                    }
                } catch(ex) {
                    console.log(ex);
                } 

                return respState;
            }
            console.log('signing in ...');
            await this.SignIn(usePopup);
        }

        if (tempAccount != null) {
            console.log('account retrieved ...');
            this.account = tempAccount;
        }
        else {
            console.log('no account ...');
        }

        return respState;
    }

    private async TryGetAccount(): Promise<AccountInfo | null> {
        if (this.account == null) {
            const accounts = this.$msalInstance.getAllAccounts();
            if (accounts.length == 0) {
                return null;
            }
            return accounts[0];
        }
        return this.account;
    }

    public async SignIn(withPopup?: boolean) {
        const { intendedRoute } = storeToRefs(useAppStore());
        let queryParams: any = {};
        if(intendedRoute.value) {
            const params = new URLSearchParams(intendedRoute.value?.split("?")[1]);
            queryParams = Object.fromEntries(params);
        }

        const account = this.account?.username || queryParams['loginHint'];
        if(withPopup) {
            try {
                const authResponse = await this.$msalInstance.loginPopup({ extraScopesToConsent: ["User.Read"], scopes: ["api://3a9ea044-f3dd-48d5-a4e0-a092ff0568ec/UNA", "User.Read"], prompt: 'select_account', loginHint: account, redirectUri: location.origin + "/app/login", state: intendedRoute.value });
                if(authResponse.account?.name) {
                    this.account = authResponse.account;
                    window.location.href = intendedRoute.value || '/app';
                }
            } catch(ex){
               console.error(ex);
            }
        } else {
            await this.$msalInstance.loginRedirect({ extraScopesToConsent: ["User.Read"], scopes: ["api://3a9ea044-f3dd-48d5-a4e0-a092ff0568ec/UNA", "User.Read"], prompt: 'select_account', loginHint: account, redirectUri: location.origin + "/app/login", state: intendedRoute.value });
        }
    }

    public xApiToken: string | null = null;
    private accessToken: string | null = null;
    private accessTokenExpiresOn: Date | null = null;
    public async AquireToken(allowRedirect: boolean = true) {
        try {
            if (this.accessToken != null && this.accessTokenExpiresOn != null && this.accessTokenExpiresOn.getTime() > new Date().getTime())
                return this.accessToken;

            console.log("acquireTokenSilent start");
            const idTokenResponse = await this.$msalInstance.acquireTokenSilent({ scopes: ["api://3a9ea044-f3dd-48d5-a4e0-a092ff0568ec/UNA"] });
            console.log("acquireTokenSilent end");
            if (idTokenResponse.accessToken) {
                this.accessToken = idTokenResponse.accessToken;
                this.accessTokenExpiresOn = idTokenResponse.expiresOn;
                return this.accessToken;
            }

            return null;
        }
        catch (e) {
            console.log(e);
            if (allowRedirect)
                await this.SignIn();
        }
    }

    public async UserAvatar() {
        if (this.xApiToken != null)
            return undefined;

        const idTokenResponse = await this.$msalInstance.acquireTokenSilent({ scopes: ["User.Read"] });
        if (idTokenResponse.accessToken) {
            const accessToken = idTokenResponse.accessToken;
            if (accessToken) {
                try {
                    const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
                        headers: { Authorization: 'Bearer ' + accessToken },
                    });
                    if (response.status == 200) {
                        const pictureBlob = await response.blob();
                        return URL.createObjectURL(pictureBlob);
                    }
                }
                catch (err) {
                    console.log(err);
                }
            }
        }

        return undefined;
    }

    public UserInitials() {
        if (this.userDetails && this.userDetails.name) {
            const parts = this.userDetails.name.split(' ');
            let initials = '';
            for (let i = 0; i < parts.length; i++) {
                initials += parts[i][0];
            }
            return initials.toUpperCase();
        }
        return undefined;
    }

    async SignOut() {
        await (!window.location.pathname.startsWith("/embed") ?
            this.$msalInstance
                .logoutPopup({postLogoutRedirectUri: `${window.location.origin}/app/logout-success`, mainWindowRedirectUri: `${window.location.origin}/app/logout-success`, account: this.account})
                .catch(error => {
                    console.error(error);
                })
            :
            this.$msalInstance
            .logoutPopup({postLogoutRedirectUri: `${window.location.origin}/app/logout-success`, account: this.account})
            .catch(error => {
                console.error(error);
            })    
        );    
    }
}
