import type { DimensionsDictionary } from "@/Scripts/DataSourceModel";
import { DimensionMember, type Dimension } from "@/apiclient/client";
import { MetaService } from "@/apiclient/services";
import { globalForInfographic } from "@/utils/keys";
import { useQueries, useQuery, type UseQueryDefinedReturnType, type UseQueryReturnType } from "@tanstack/vue-query";
import { computed, toRefs, ref, type Ref, watchEffect, inject } from "vue";



export function useDimensions(): UseQueryReturnType<Dimension[], Error> {
    return useQuery(
        {
            queryKey: ['dimensions'],
            queryFn: () => MetaService.getDimensions(undefined),
            staleTime: 30 * 1000,
            refetchOnWindowFocus: false
        }
    );
}

export function useDimensionsOFTypeOther(): UseQueryReturnType<Dimension[], Error> {
    return useQuery(
        {
            queryKey: ['dimensions'],
            queryFn: () => MetaService.getDimensions(undefined),
            staleTime: 30 * 1000,
            select: (dimensions) => dimensions.filter(dim => dim.type === "Segment"),
            refetchOnWindowFocus: false
            // initialData: []
        }
    );
}

export function useAllDimensions({dimensions, dimensionsFilter }: { dimensionsFilter?: (dimension: Dimension) => boolean, dimensions: Ref<Dimension[]> | Ref<undefined> }) {
    const isReady = ref<boolean>();
    const data = ref<DimensionsDictionary>({});
    
    const dimensionsQueriesList = computed(() => {
        if(dimensions.value) {
            return dimensions.value?.filter((dim) => dimensionsFilter ? dimensionsFilter(dim) : true).map(dimension => 
                ({
                    queryKey: ["dimension", dimension.name],
                    queryFn: () => MetaService.getDimensionMembers(dimension.name!, "Main"),
                    staleTime: 30 * 1000,
                    refetchOnWindowFocus: false
                    // enabled: !isFetching.value
                })
             );
        }
        return [];
    });
    const dimensionsQueries = useQueries({
        queries: dimensionsQueriesList,
        combine: (results) => {
            const isReady = !!dimensionsQueriesList.value.length &&  results.every(result => result.isSuccess && result.isFetched);
            if(!isReady) {
                return { isReady: false, dimensionsDictionary: {}};
            }
            const resultDictionary = results.reduce<DimensionsDictionary>((result, obj, idx) => {
                if(dimensions.value) {
                    result[dimensions.value[idx].name!] = obj.data || [];
                }
                return result;
            }, {});
            return { isReady: true,  dimensionsDictionary: resultDictionary };
        }
    });

    watchEffect(() => {
        isReady.value = dimensionsQueries.value.isReady;
        data.value = dimensionsQueries.value.dimensionsDictionary;
    })
    // const { isReady, dimensionsDictionary } =  toRefs(dimensionsQueries.value);
    // const isReady = computed(() => { 
    //     return dimensionsQueries.value.isReady;
    //  });
    // const dimensionsDictionary = computed(() => dimensionsQueries.value.dimensionsDictionary);


    return { isReady, data };
}

 export function useSingleDimension({dimensionName, select, segmentDimensionName, isEnabled } : {dimensionName: string | undefined, isEnabled?: Ref<boolean>, select?: ((data: DimensionMember[]) => DimensionMember[]) | undefined, segmentDimensionName?: Ref<string | undefined>}): UseQueryDefinedReturnType<DimensionMember[], Error> {
     const isQueryEnabled = computed(() => (dimensionName !== undefined || segmentDimensionName!.value !== undefined) && (!isEnabled || isEnabled.value !== false));
     const forInfographic = inject<boolean>(globalForInfographic, false);
    
     if (dimensionName === 'Stage') {
         dimensionName = forInfographic ? 'Standard Stage' : 'Stage';
     }
     return useQuery({
         queryKey: ["dimension", dimensionName, forInfographic],
         queryFn: ({queryKey}) => MetaService.getDimensionMembers(queryKey[1] as string, "Main"),
         initialData: [],
         select,
         enabled: isQueryEnabled,
         refetchOnWindowFocus: false
       });
 }

//export function useSingleDimension({dimensionName, select, segmentDimensionName } : {dimensionName: string | undefined, select?: ((data: DimensionMember[]) => DimensionMember[]) | undefined, segmentDimensionName?: Ref<string | undefined>}) {
//    // const data = ref<DimensionMember>([]);
//    const hasDimensionName = computed(() => dimensionName !== undefined || segmentDimensionName!.value !== undefined);
//    const singleDimensionQuery = computed(() => {
//        const forInfographic = inject<boolean>(globalForInfographic, false);
    
//        if (dimensionName === 'Stage') {
//            dimensionName = forInfographic ? 'Standard Stage' : 'Stage';
//        }
//        const xxx =  useQuery({
//            queryKey: ["dimension", dimensionName, forInfographic],
//            queryFn: ({queryKey}) => MetaService.getDimensionMembers(queryKey[1] as string, "Main"),
//            initialData: [],
//            select,
//            enabled: hasDimensionName
//        });

//        return xxx;
//    })

//    // watchEffect(() => {
//    //     data.value = DimensionMember.fromJS(singleDimensionQuery.value);
//    // })

//    return singleDimensionQuery.value;
//}

export function useKpiList(isInternal: boolean = false): UseQueryDefinedReturnType<DimensionMember[], Error> {
    return useSingleDimension({ dimensionName: "Kpi", 
        select: (data) => {
            if (isInternal) {
                return data.map(kpi => {
                    if (kpi.properties && kpi.properties.kpitype === '0') {
                        kpi.name = 'Volume';
                    }
                    if (kpi.properties && kpi.properties.kpitype === '2') {
                        kpi.name = 'TotalDays';
                    }
                    return kpi;
                });
            }
            else {
                return data.filter(dim => dim.properties?.bowtiemetric === "1");
            }
        }
    });
}

export function useStandardStagesList(): UseQueryDefinedReturnType<DimensionMember[], Error> {
    const { data: stagesList, isFetched: isStagesFetched } = useSingleDimension({ dimensionName: "Stage" });

    return useSingleDimension({dimensionName: "Standard Stage", isEnabled: isStagesFetched,
        select: (stages) => stages.filter(stStg => stagesList.value.filter(stg => stg.properties?.standardstageid === stStg.id?.toString()).length)});
}