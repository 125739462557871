<script setup lang="ts">
import { useActions } from '@/queries/actions';
import { ref } from 'vue';
import { BusinessAction } from '@/apiclient/client';
import { getDatePlus7Days } from '@/utils/dateNumberUtils';

    const visible = defineModel<boolean>('visible', { default: false });
    const createOrUpdateActionModalVisible = ref(false);
    
    const selectedActionModel = ref(BusinessAction.fromJS({...BusinessAction.fromJS({}), dueDate: getDatePlus7Days(), description: '' }))


    const actionStatus = ref(1);
    const actionStatusOptions = ref([ {name: 'All', value: 0}, {name: 'Complete', value: 1}, {name: 'Not complete', value: 2}] );

    const allActions = ref(false);
    const myActionsOptions = ref([ {name: 'Mine', value: false}, {name: 'All', value: true}] );

    const { data: actions, isFetching } = useActions(visible, actionStatus, allActions);


</script>
<template>
    <Drawer v-model:visible="visible" header="Actions" position="right" style="width:40dvw" >
        <template #header>
            <!-- TBD -->
        </template>
        <div>
            <!-- actionId?: number;
    owner!: string | undefined;
    description?: string | undefined;
    createdDate?: Date;
    dueDate?: Date | undefined;
    status!: string | undefined;
    comments?: string | undefined;
    newComment?: string | undefined;
    users?: string[] | undefined;
    attachments?: Attachment[] | undefined; -->
            <div class="row">
                <div class="col-5">
                    <label class="font-semibold">Status</label>
                    <SelectButton v-model="actionStatus" :options="actionStatusOptions" optionLabel="name" optionValue="value"  class="w-100" />
                </div>
                <div class="col-2 d-flex flex-column">
                    <label class="font-semibold">Owner</label>
                    <SelectButton v-model="allActions" :options="myActionsOptions" optionLabel="name" optionValue="value"  class="w-100" />

                    <!-- <InputSwitch v-model="myActions" /> -->
                </div>
            </div>
            <DataTable :data="actions" :loading="isFetching">
                <Column field="description" header="Description">
                </Column>
                <Column field="createdDate" header="CreatedDate">
                </Column>
                <template #header>
                    <div class="text-right">
                        <Button icon="pi pi-plus" :label="undefined" :severity="'secondary'" icon-only="true"  icon-pos="right" @click="() => {
                            createOrUpdateActionModalVisible = true;
                        }" />
                    </div>
                </template>
            </DataTable>
        </div>
        <CreateOrUpdateActionModal v-if="createOrUpdateActionModalVisible" v-model:visible="createOrUpdateActionModalVisible" v-model:actionModel="selectedActionModel" />
    </Drawer>
</template>