import dayjs from 'dayjs';

export function monthToNumber(month: string) {
  switch (month) {
    case "Jan":
      return "01";
    case "Feb":
      return "02";
    case "Mar":
      return "03";
    case "Apr":
      return "04";
    case "May":
      return "05";
    case "Jun":
      return "06";
    case "Jul":
      return "07";
    case "Aug":
      return "08";
    case "Sep":
      return "09";
    case "Oct":
      return "10";
    case "Nov":
      return "11";
    case "Dec":
      return "12";
  }
}

export function numberToMonth(month: string | number) {
  if(typeof month === 'number' && month < 10) {
    month = `0${month}`;
  }
  switch (month.toString()) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "Apr";
    case "05":
      return "May";
    case "06":
      return "Jun";
    case "07":
      return "Jul";
    case "08":
      return "Aug";
    case "09":
      return "Sep";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    case "12":
      return "Dec";
    default:
      return month;
  }
}

export function getQuarterFromMonth(month: number) {
  return Math.floor((month - 1) / 3 + 1);
}

export function getQuarterFromDate(date: number) {
  return date % 10;
}

export function getMonthsBoundariesOfQuarter(quarter: number): [number, number] {
  switch (quarter) {
    case 1:
      return [1, 3];
    case 2:
      return [4, 6];
    case 3:
      return [7, 9];
    case 4:
      return [10, 12];
    default:
        return [0, 13];
  }
}

export function getMonthName(year: number, month: number) {
  return new Date(year, month - 1).toLocaleString('en-US', { month: 'long' });
}

export function getDateNumberFromYearAndMonth(year?: number, month?: number) {
  if(!year || !month) {
    return 0;
  }
  return year * 100 + month;
}

export function getMonthFromDateNumber(date: number) {
  return Math.floor(date % 100);
}

export function getYearFromDateNumber(date: number) {
  if(isYear(date)) {
    return date;
  }

  if(isQuarter(date)) {
    return Math.floor(date / 10);
  }

  return Math.floor(date / 100);
}

export function ensureCorrectDateNumber(date: number) {
  const month = getMonthFromDateNumber(date);
  if(month > 12) {
    return date + 88;
  }
  if(month === 0) {
    return date - 88;
  } else {
    return date;
  }
}

export function isYear(date?: number) {
  return !!date && date.toString().length === 4;
}

export function isQuarter(date?: number) {
  return !!date && date.toString().length === 5;
}

export function isMonth(date?: number) {
  return !!date && date.toString().length === 6;
}

export function formatDate(value: Date) {
  const date = dayjs(value);
  
  return date.format('YYYY-MM-DD HH:mm:ss');
}

export function getDatePlus7Days():Date {
  const currentDate = new Date(); // Get the current date
  const resultDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // Add 7 days in milliseconds
  return resultDate;
}
