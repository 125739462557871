<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { splitByCapitals } from '@/utils/misc';

   type Props = {
        name: string;
        type?: string;
    }
    const { name } = withDefaults(defineProps<Props>(), {
    });

    const field = useField(name);
    const { meta, errorMessage, errors } = field;

    // defineExpose({
    //     field,
    //     meta
    // });
</script>
<template>
        <label for="name" class="font-semibold text-capitalize" :class="{ 'me-2': type === 'checkbox' }">{{splitByCapitals(name)}}</label>
        <slot :field="field" :meta="meta" :fieldValue="field.value" :invalid="meta.touched && errors.length"></slot>
        <small style="color: red">{{ errorMessage }}</small>
</template>