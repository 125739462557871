import { ActionServiceProxy, ApiServiceProxy, DimensionServiceProxy, FileSystemServiceProxy, HubspotServiceProxy, MetaServiceProxy, ModelServiceProxy, QueryServiceProxy, ReportServiceProxy, SecurityServiceProxy } from '@/apiclient/client';
import axios from 'axios';
import { useAppStore } from '@/stores/app';

//const isProd = import.meta.env.PROD;
//const { baseUrl } = await import(!isProd ?"@/configuration/appconfig.json" : "@/configuration/appconfig.development.json");
const file = await axios.get("/appconfig.json")
const { baseUrl } = file.data;

const instance = axios.create({ baseURL: baseUrl });


instance.interceptors.request.use(async (config) => {
    const { autentication } = useAppStore();

    const xApiToken = autentication.xApiToken;

    if (xApiToken != null)
        config.headers["x-api-token"] = xApiToken;
    else {
        const token = await autentication.AquireToken();
        config.headers.Authorization = "Bearer " + token;
    }

    const tenant = autentication.tenant;
    if (tenant != null)
        config.headers.set("Tenant", tenant);

    return config;
});

export { instance as axiosInstance };
export const MetaService = new MetaServiceProxy(baseUrl, instance);
export const QueryService = new QueryServiceProxy(baseUrl, instance);
export const FileSystemService = new FileSystemServiceProxy(baseUrl, instance);
export const HubspotService = new HubspotServiceProxy(baseUrl, instance);
export const SecurityService = new SecurityServiceProxy(baseUrl, instance);
export const ReportService = new ReportServiceProxy(baseUrl, instance);
export const DimensionService = new DimensionServiceProxy(baseUrl, instance);
export const ModelService = new ModelServiceProxy(baseUrl, instance);
export const ActionService = new ActionServiceProxy(baseUrl, instance);


