<script setup lang="ts">
    import { useElementSize } from '@vueuse/core';
    import { provide, ref, onMounted, onBeforeMount, inject } from 'vue';
    import { RouterView, useRoute, useRouter } from 'vue-router'
    import { globalElementWidth, globalElementHeight } from './utils/keys';
    import BlockUI from 'primevue/blockui';
    import ProgressSpinner from 'primevue/progressspinner';
    import { useAppStore } from '@/stores/app';
    import { storeToRefs } from 'pinia';
    import { SecurityService, axiosInstance } from './apiclient/services';
    import { UserTenant } from './apiclient/client';
    import type { AxiosError } from 'axios';
    import { useToast } from 'primevue/usetoast';
    import { useConfirm } from 'primevue/useconfirm';
    import { useFiltersStore } from './stores/filters';

    const primaryColor = null;
    const el = ref(null);
    const { width: mainWidth, height: mainHeight } = useElementSize(el);
    provide(globalElementWidth, mainWidth);
    provide(globalElementHeight, mainHeight);
    const appStore = useAppStore();
    const { isMainSpinnerVisible, autentication, intendedRoute, isAuthenticated, accounts, tenants, isAppLoaded } = storeToRefs(appStore);

    const toast = useToast();
    const confirm = useConfirm();

    axiosInstance.interceptors.response.use(undefined, async (error: AxiosError) => {
        if (error.response?.status === 401) {
            confirm.require({
                message: 'Your session has expired. You will be redirected to the login page.',
                header: 'Expired session',
                icon: 'pi pi-exclamation-triangle',
                position: 'top',
                // rejectClass: 'p-button-secondary p-button-outlined',
                // rejectLabel: 'Cancel',
                acceptLabel: 'Ok',
                accept: () => {
                    window.location.reload();
                    // toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
                }
            });
        } else {
            toast.add({ severity: 'error', summary: 'Error!', detail: error.message, life: 3000 });
        }
    });

    // useErrorHandling();
    onBeforeMount(async () => {
        try {
            appStore.showMainSpinner();
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            const queryParams = Object.fromEntries(params);
            if (queryParams["xapitoken"] != null)
                autentication.value.xApiToken = queryParams["xapitoken"];
            if (queryParams["tenant"] != null) {
                localStorage.setItem('selected-tenant', queryParams["tenant"] );
            }

            if (autentication.value.xApiToken == null) {
                const respState = await autentication.value.Initialize(window.location.pathname.startsWith("/embed"));
                if (respState) {
                    intendedRoute.value = respState;
                }
            }
            accounts.value = await autentication.value.GetAllAccounts() ?? [];
            var account = autentication.value.account;
            if (account != null || autentication.value.xApiToken != null) {
                var found = accounts.value.find(a => a.username == account!.username);
                if (account != null && found == null)
                    accounts.value.push(account);

                try {
                    var newTenants = await SecurityService.myTenants();
                    tenants.value.push(...newTenants);
                    // tenants.value.push( UserTenant.fromJS({ tenant: 'Test', environment: 'local'}));

                    const selectedTenantFromLocalStorage = localStorage.getItem('selected-tenant');
                    const selectedTenant = tenants.value.find(ten => ten.tenant === selectedTenantFromLocalStorage);
                    if (selectedTenant) {
                        await selectTenant(selectedTenant);
                    }

                    // appStore.hideMainSpinner();

                }
                catch (ex) {
                    console.log('Error getting tenants');
                    alert("Could not retrieve user tenants.");
                }
            }
        } finally {
            isAppLoaded.value = true;
            appStore.hideMainSpinner();
        }
    });

    async function selectTenant(userTenant: UserTenant) {
        autentication.value.tenant = userTenant.tenant ?? null;
        var userDetails = await SecurityService.myUserDetails();
        autentication.value.userDetails = userDetails;
    }

    onMounted(() => {
        if (primaryColor) {
            document.documentElement.style.setProperty('--primary-color', primaryColor);
        }
    })
</script>

<template>
    <main ref="el">
      <BlockUI :blocked="isMainSpinnerVisible" :fullScreen="true"></BlockUI>
      <ProgressSpinner v-show="isMainSpinnerVisible" class="overlay"/>
      <template v-if="isAppLoaded">
        <Suspense>
          <RouterView />
        </Suspense>
      </template>
      
    </main>
    <ConfirmDialog :pt="{
      pcRejectButton: { root: 'd-none' }
    }"></ConfirmDialog>
    <Toast />
  </template>

<style scoped>
    main {
      /* width: calc(100vw - 75px); */
    }
</style>
<style lang="scss">

.overlay {
    position:fixed !important;
    top: 45%;
    left: 45%;
    width: 10% !important;
    height: 10% !important;
    z-index: 100; /* this seems to work for me but may need to be higher*/
}

.form-select {
  padding: 0 !important;
}
</style>
