<script setup lang="ts">
    import { computed, watch, ref, provide } from 'vue';
    import { storeToRefs } from 'pinia'
    import AppTopbar from './AppTopbar.vue';
    import AppFooter from './AppFooter.vue';
    import AppSidebar from './AppSidebar.vue';
    import AppLogin from './AppLogin.vue';
    import { useLayout } from '@/layout/composables/layout';
    import { useAppStore } from '@/stores/app';
    import { globalElementWidth, globalElementHeight } from '@/utils/keys';
    import { useElementSize } from '@vueuse/core';

    const { layoutConfig, layoutState, isSidebarActive, isSiderbarCollapsed } = useLayout();
    const { autentication } = storeToRefs(useAppStore());

    const outsideClickListener = ref();
    const el = ref(null);
    const { width: mainWidth, height: mainHeight } = useElementSize(el);
    provide(globalElementWidth, mainWidth);
    provide(globalElementHeight, mainHeight);
    watch(isSidebarActive, (newVal) => {
        if (newVal) {
            bindOutsideClickListener();
        } else {
            unbindOutsideClickListener();
        }
    });

    const containerClass = computed(() => {
        return {
            'layout-theme-light': layoutConfig.darkTheme.value && 'light',
            'layout-theme-dark': layoutConfig.darkTheme.value && 'dark',
            'layout-overlay': layoutConfig.menuMode.value === 'overlay',
            'layout-static': layoutConfig.menuMode.value === 'static',
            'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
            'layout-overlay-active': layoutState.overlayMenuActive.value,
            'layout-mobile-active': layoutState.staticMenuMobileActive.value,
            'p-input-filled': layoutConfig.inputStyle.value === 'filled',
            'p-ripple-disabled': !layoutConfig.ripple.value
        };
    });
    const bindOutsideClickListener = () => {
        if (!outsideClickListener.value) {
            outsideClickListener.value = (event: any) => {
                if (isOutsideClicked(event)) {
                    layoutState.overlayMenuActive.value = false;
                    layoutState.staticMenuMobileActive.value = false;
                    layoutState.menuHoverActive.value = false;
                }
            };
            document.addEventListener('click', outsideClickListener.value);
        }
    };
    const unbindOutsideClickListener = () => {
        if (outsideClickListener.value) {
            document.removeEventListener('click', outsideClickListener as any);
            outsideClickListener.value = null;
        }
    };
    const isOutsideClicked = (event: { target: Node | null; }) => {
        const sidebarEl = document.querySelector('.layout-sidebar');
        const topbarEl = document.querySelector('.layout-menu-button');

        return !(sidebarEl?.isSameNode(event.target) || sidebarEl?.contains(event.target) || topbarEl?.isSameNode(event.target) || topbarEl?.contains(event.target));
    };
</script>

<template>
    <div class="layout-wrapper" :class="containerClass">
        <app-topbar></app-topbar>
        <div class="layout-sidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="layout-main-container" ref="el">
            <div class="layout-main">
                <router-view></router-view>
            </div>
            <app-footer></app-footer>
        </div>
        <!--<app-config></app-config> -->
        <div class="layout-mask"></div>
    </div>
    <!-- <app-login v-else></app-login> -->
</template>

<style lang="scss" scoped>
    // width: calc(100dvw - 300px);
    .layout-main-container {
        max-height: calc(100dvh - 70px - 7rem);
        overflow-x: hidden;
        overflow-y: auto;
    }
</style>
