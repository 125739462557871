
import { BusinessAction } from "@/apiclient/client";
import { ActionService } from "@/apiclient/services";
import { useMutation, useQueries, useQuery, useQueryClient, type UseQueryDefinedReturnType, type UseQueryReturnType } from "@tanstack/vue-query";
import { computed, type Ref } from "vue";

export function useActions(isActive: Ref<boolean>, actionStatus: Ref<number>, allActions: Ref<boolean>): UseQueryReturnType<BusinessAction[], Error> {
    const queryKey = computed(() => {
        return {
            actionStatus,
            allActions
        }
    });

    return useQuery(
        {
            queryKey: ['actions', queryKey],
            queryFn: ({queryKey}) => ActionService.getActions((queryKey[1] as any).allActions , (queryKey[1] as any).actionStatus),
            // queryFn: ({queryKey}) => ActionService.getActions((queryKey[1] as any) , (queryKey[2] as any)),

            staleTime: 30 * 1000,
            enabled: isActive,
            refetchOnWindowFocus: true
        }
    );
}

export function createAction(actionStatus: Ref<number>, allActions: Ref<boolean>) {
    const queryKey = computed(() => {
        return {
            actionStatus,
            allActions
        }
    });
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['createAction'],
        mutationFn: (data: BusinessAction) => ActionService.createAction(data),
        onSuccess: (data, variables, context) => {
        //   queryClient.setQueryData(['actions'], (oldData: DimensionInfo[]) => {
        //     const oldDimensionIdx = oldData.findIndex(dim => dim.name === variables.originalName);
        //     const { data } = variables;
        //     if(!~oldDimensionIdx) {
        //         return [...oldData, data].sort((a, b) => a.name.localeCompare(b.name));      
        //     } else {
        //         return [...oldData.slice(0, oldDimensionIdx), data, ...oldData.slice(oldDimensionIdx + 1)].sort((a, b) => a.name.localeCompare(b.name));
        //     }
        //   });
        },
        onSettled: (data, error, variables, context) => {
            if (!error) {
                queryClient.invalidateQueries({ queryKey: ['actions', queryKey] });
            }
        }
    });
}