import type { MaybeRefOrGetter } from "vue";

export function toRawObject(obj: MaybeRefOrGetter<any>) {
    return JSON.parse(JSON.stringify(obj));
}

export function splitByCapitals(input: string): string {
    return input.split(/(?<![A-Z])(?=[A-Z])/).map(part => part.trim()).join(" ");
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDataFieldTypeString(shortType: string): string {
    switch(shortType) {
        case 'd': return 'date';
        case 'i':case 'n': return 'numeric';
        case 's': return 'text';
        case 'b': return 'boolean';

        default: return 'text';
    }
}

export function safeStringify(obj: MaybeRefOrGetter<any>) {
    const seen = new WeakSet();
    
    return JSON.parse(JSON.stringify(obj, (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    }));
}