import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import type { AccountInfo } from '@azure/msal-browser';
import type { Ref } from 'vue';
import authClient from '../apiclient/authClient';
import type { UserTenant } from '@/apiclient/client';

export const useAppStore = defineStore('app', () => {
    const isAppLoaded = ref(false);
    const isMainSpinnerVisible = ref(false);
    const autentication = ref(new authClient());
    const tenants: Ref<UserTenant[]> = ref([]);
    const accounts: Ref<AccountInfo[]> = ref([]);
    const isAuthenticated = computed(() => autentication.value.tenant);
    const intendedRoute = ref<string | undefined>();

    function showMainSpinner() {
        if (!isMainSpinnerVisible.value) {
            isMainSpinnerVisible.value = true;
        }
    }

    function hideMainSpinner() {
        if (isMainSpinnerVisible.value) {
            isMainSpinnerVisible.value = false;
        }
    }

    return { autentication, isMainSpinnerVisible, showMainSpinner, hideMainSpinner, isAuthenticated, intendedRoute, accounts, tenants, isAppLoaded }
})