<script setup lang="ts">
    import Icon1 from '@/styles/assets/icons/Icons-1.svg';
    import Icon2 from '@/styles/assets/icons/Icons-2.svg';
    import Icon3 from '@/styles/assets/icons/Icons-3.svg';
    import Icon4 from '@/styles/assets/icons/Icons-4.svg';
    import Icon5 from '@/styles/assets/icons/Icons-5.svg';
    import Icon6 from '@/styles/assets/icons/Icons-6.svg';
    import Icon7 from '@/styles/assets/icons/Icons-7.svg';
    import Icon8 from '@/styles/assets/icons/Icons-8.svg';
    import Icon9 from '@/styles/assets/icons/Icons-9.svg';

    import Icon10 from '@/styles/assets/icons/Icons-10.svg';
    import Icon11 from '@/styles/assets/icons/Icons-11.svg';
    import Icon12 from '@/styles/assets/icons/Icons-12.svg';
    import Icon13 from '@/styles/assets/icons/Icons-13.svg';
    import Icon14 from '@/styles/assets/icons/Icons-14.svg';
    import Icon15 from '@/styles/assets/icons/Icons-15.svg';
    import Icon16 from '@/styles/assets/icons/Icons-16.svg';
    import Icon17 from '@/styles/assets/icons/Icons-17.svg';
    import Icon18 from '@/styles/assets/icons/Icons-18.svg';
    import Icon19 from '@/styles/assets/icons/Icons-19.svg';

    import Icon20 from '@/styles/assets/icons/Icons-20.svg';
    import Icon21 from '@/styles/assets/icons/Icons-21.svg';
    import Icon22 from '@/styles/assets/icons/Icons-22.svg';
    import Icon23 from '@/styles/assets/icons/Icons-23.svg';
    import Icon24 from '@/styles/assets/icons/Icons-24.svg';
    import Icon25 from '@/styles/assets/icons/Icons-25.svg';
    import Icon26 from '@/styles/assets/icons/Icons-26.svg';
    import Icon27 from '@/styles/assets/icons/Icons-27.svg';
    import Icon28 from '@/styles/assets/icons/Icons-28.svg';
    import Icon29 from '@/styles/assets/icons/Icons-29.svg';
    import Icon30 from '@/styles/assets/icons/Icons-30.svg';
    import Icon31 from '@/styles/assets/icons/Icons-31.svg';
    import Icon32 from '@/styles/assets/icons/Icons-32.svg';

    const { iconName } = defineProps({
      iconName: {
        type: String
      },
    });

    const getIcon = () => {
        switch(iconName) {
            case 'Icon-1': {
                return Icon1;
            }
            case 'Icon-2': {
                return Icon2;
            }
            case 'Icon-3': {
                return Icon3;
            }
            case 'Icon-4': {
                return Icon4;
            }
            case 'Icon-5': {
                return Icon5;
            }
            case 'Icon-6': {
                return Icon6;
            }
            case 'Icon-7': {
                return Icon7;
            }
            case 'Icon-8': {
                return Icon8;
            }
            case 'Icon-9': {
                return Icon9;
            }
            case 'Icon-10': {
                return Icon10;
            }
            case 'Icon-11': {
                return Icon11;
            }
            case 'Icon-12': {
                return Icon12;
            }
            case 'Icon-13': {
                return Icon13;
            }
            case 'Icon-14': {
                return Icon14;
            }
            case 'Icon-15': {
                return Icon15;
            }
            case 'Icon-16': {
                return Icon16;
            }
            case 'Icon-17': {
                return Icon17;
            }
            case 'Icon-18': {
                return Icon18;
            }
            case 'Icon-19': {
                return Icon19;
            }
            case 'Icon-20': {
                return Icon20;
            }
            case 'Icon-21': {
                return Icon21;
            }
            case 'Icon-22': {
                return Icon22;
            }
            case 'Icon-23': {
                return Icon23;
            }
            case 'Icon-24': {
                return Icon24;
            }
            case 'Icon-25': {
                return Icon25;
            }
            case 'Icon-26': {
                return Icon26;
            }
            case 'Icon-27': {
                return Icon27;
            }
            case 'Icon-28': {
                return Icon28;
            }
            case 'Icon-29': {
                return Icon29;
            }
            case 'Icon-30': {
                return Icon30;
            }
            case 'Icon-31': {
                return Icon31;
            }
            case 'Icon-32': {
                return Icon32;
            }
            default: {
                return null; 
            }
        }
    }

</script>


<template>
    <component :is="getIcon()" class="p-icon u-icon-large" ></component>
</template>

<style lang="scss" scoped>
    .u-icon {
        &-large {
            height: var(--p-icon-large);
            width: var(--p-icon-large);
        }
        &-medium {
            height: var(--p-icon-medium);
            width: var(--p-icon-medium);
        }

        &-small {
            height: var(--p-icon-small);
            width: var(--p-icon-small);
        }
    }
</style>