import { ref, computed, reactive, provide, inject, type Ref, watchEffect, watch, onBeforeUnmount } from "vue";
import { defineStore,  } from "pinia";
import type {
DataSourceGroupedModelSimplified,
  DimensionsDictionary,
} from "@/Scripts/DataSourceModel";
import _ from "lodash";
import type { BowTieStage, BowtieCategory, BowtieSelectedItem } from "@/Scripts/BowTieCategory";
import { Dimension, DimensionMember, RequestMode, TimePerspective,  } from "@/apiclient/client";
import { injectLocal, provideLocal, useStorage } from '@vueuse/core'
import { filtersStore } from "@/utils/keys";
import type { BowTieFilterMode } from "@/components/storeContext/mappings";
import { useSingleDimension } from "@/queries/dimensions";
import { onBeforeRouteLeave, useRoute } from "vue-router";

export type FilterModel = {
  period?: number;
  periodToDate?: TimePerspective;
  // segment?: number;
  stages?: DimensionMember[];
  logScale?: boolean;
  showAxes?: boolean;
  mirrorView?: boolean;
  scenario?: number;
  compareWithPrevious?: boolean;
  selectedStages?: BowtieSelectedItem[];
  timeScaling?: boolean;

  [key: string]: any;
  kpi?: number;
};

export const defaultState = {
  stages: [],
  showAxes: false,
  mirrorView: true,
  logScale: false,
  scenario: 1,
  periodToDate: 1,
  timeScaling: true,
  period: new Date().getFullYear(),
  kpi: 1
};
export function createFilterModel(initialState?: Partial<FilterModel>): FilterModel {
  return {...defaultState, ...{ period: new Date().getFullYear() }, ...(initialState || {})}
}

export function additionalFiltersAreModified(filterModel: FilterModel) {
  return filterModel.logScale !== defaultState.logScale || filterModel.timeScaling !== defaultState.timeScaling || filterModel.showAxes !== defaultState.showAxes || filterModel.mirrorView !== defaultState.mirrorView;
}
// export type PeriodToDate = "YTD" | "QTD" | "MTD";
export type FilterStoreModel = { filterModel: FilterModel, displayMode: BowTieFilterMode, resetFilterModel: any};

export type FilterStoreState = { filterModel: Ref<FilterModel>, displayMode: Ref<BowTieFilterMode>, resetFilterModel: any};

export const useFiltersStore = (scopeId?: string, initialState?: Partial<FilterModel>, skipProvide?: boolean, initialImmutableState?: Partial<FilterModel>) => {
  if(scopeId && !skipProvide) {
    provide(filtersStore, scopeId);
  }
  const storeId =  scopeId || inject<string>(filtersStore, filtersStore.description!);

  return defineStore(storeId, () => {
    // const dimensionsList = ref<Dimension[]>([]);
    // const dimensionsDictionary = ref<DimensionsDictionary>({});
    // const segmentListDb = ref<DimensionMember[]>([]);
    // const stageListDb = ref<DimensionMember[]>([]);
    // const periodListDb = ref<DimensionMember[]>([]);
    // const timePerspectiveListDb = ref<DimensionMember[]>([]);
    // const measureListDb = ref<DimensionMember[]>([]);
    // const kpiListDb = ref<DimensionMember[]>([]);
    // const scenarioListDb = ref<DimensionMember[]>([]);
    // const dynamicDimensionsListDb = ref<{ name: string, listDb: DimensionMember[] }[]>();

    const filterModel = ref<FilterModel>(initialImmutableState || createFilterModel(initialState));
    const displayMode = ref<BowTieFilterMode>('default');
    const selectedPatchedChartData = ref<(DataSourceGroupedModelSimplified | undefined)[]>([]);
    const selectedPatchedSecondaryData = ref<(DataSourceGroupedModelSimplified | undefined)[]>([]);
    const selectedBowtieInfoTab = ref<string>("1");
    const isCompareMode = ref<boolean>(false);
    const isActual = ref<boolean>(false);
    const requestMode = ref<RequestMode>(RequestMode.KpiTrending);
    const sankeyMode = ref<boolean>(false);
    const selectedTrendsChartIdx = ref<number>();
    

    const { isFetching, isSuccess, data} = useSingleDimension({dimensionName: "Stage"});

    watch(() => data.value, (newData) => {
        if (!isFetching.value && isSuccess.value && !filterModel.value.stages?.length && filterModel.value.stages?.length !== newData.length) {
          setAvailableStages(newData); 
        }
    });
    // watchEffect(() => {
    //   const { isFetching, isSuccess, data} = stageDimensionQuery;
    //    if (!isFetching.value && isSuccess.value && !filterModel.value.stages?.length && filterModel.value.stages?.length !== data.value.length) {
    //       setAvailableStages(data.value); 
    //    }
    // });
    function resetFilterModel() {
      // could be buggy
      const store = useFiltersStore(scopeId, initialState, skipProvide);
      store.$patch({ filterModel: createFilterModel()});
      // filterModel.value = createFilterModel();
    }

    function setFiltersDatasource(dictionary: DimensionsDictionary) {
      if(!dictionary) {
        return;
      }
      
      // dimensionsDictionary.value = dictionary;
      // segmentListDb.value = dictionary["Segment"];
      // filterModel.value.stages = /*stageListDb.value =*/ dictionary["Stage"];
      // periodListDb.value = dictionary["Period"];
      // timePerspectiveListDb.value = dictionary["Time Perspective"];
      // measureListDb.value = dictionary["Measure"];
      // kpiListDb.value = dictionary["Kpi"];
      // scenarioListDb.value = dictionary["Scenario"];
    }

    function setAvailableStages(stages: DimensionMember[]) {
      filterModel.value.stages = stages;
    }

    // const dynamicDimensionsListDb = computed(() => dimensionsList.value?.filter(dim => dim.type === "Other").map(dim => ({ name: dim.name!, listDb: dimensionsDictionary.value[dim.name!]})));

    return {
      filterModel,
      displayMode,
      selectedPatchedChartData,
      selectedPatchedSecondaryData,
      isActual,
      isCompareMode,
      requestMode,
      sankeyMode,
      createFilterModel,
      resetFilterModel,
      setFiltersDatasource,
      setAvailableStages,
      selectedBowtieInfoTab,
      selectedTrendsChartIdx
      // segmentListDb,
      // stageListDb,
      // periodListDb,
      // timePerspectiveListDb,
      // measureListDb,
      // kpiListDb,
      // scenarioListDb,
      // dynamicDimensionsListDb,
      // dimensionsDictionary,
      // dimensionsList
    };
  })();
};

export function useLocalStorageFilters(storeId?: string | undefined, secondary?: boolean) {
  const route = useRoute();
  const selectedTenantFromLocalStorage = localStorage.getItem('selected-tenant');
  // intended non-reactive
  const key = `${selectedTenantFromLocalStorage}_${route.path}${secondary ? '_secondary' : ''}`;
  const storage = useStorage<FilterStoreModel>(key, {} as FilterStoreModel);
  return storage;
}

export function useLocalStorageFiltersBinding(storeId?: string | undefined, secondary?: boolean) {
    const storage = useLocalStorageFilters(storeId, secondary);

    const store = useFiltersStore(storeId, storage.value, false);
    
    if(Object.keys(storage.value).length) {
       store.$patch(storage.value);
    }

    watchEffect(() => {
      if(store.filterModel) {
        const { filterModel, isCompareMode, selectedBowtieInfoTab, selectedPatchedChartData, selectedPatchedSecondaryData, displayMode, isActual, requestMode, sankeyMode} = store; 
        storage.value = { filterModel, isCompareMode, selectedBowtieInfoTab, selectedPatchedChartData, selectedPatchedSecondaryData, displayMode, isActual, requestMode, sankeyMode} as any;
        // localStorage.setItem(key, JSON.stringify(store.filterModel));
      }
    });

    return store;
}
export type FiltersStoreType = ReturnType<typeof useFiltersStore>;
