import { useAppStore } from '@/stores/app';
import { storeToRefs } from 'pinia';
import { createRouter, createWebHistory } from 'vue-router';
import { until } from '@vueuse/core'
import AppLayout from '@/layout/AppLayout.vue';
import { useAppInsights } from '../stores/appInsights';


const standaloneRoutes = [
    {
        path: 'bowtie',
        name: 'bowtie',
        component: () => import('../views/BasicBowTieView.vue')
    },
    {
        path: 'file-system',
        name: 'fileSystem',
        component: () => import('../views/FileSystemView.vue')
    },
    {
        path: 'bowtie-infographic',
        name: 'bowtieInfographic',
        component: () => import('../views/BowtieInfographicView.vue')
    },
    {
        path: 'cohorting',
        name: 'cohorting',
        component: () => import('../views/BowtieCohortingView.vue')
    },
];
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/app',
            component: AppLayout,
            redirect: { name: 'app.bowtieInfographic' },
            // beforeEnter: async (to, _, next) => {
            //     const { autentication, isAuthenticated, intendedRoute, isAppLoaded } = storeToRefs(useAppStore());
            //     // autentication.value.HandleRedirectResponse();
            //     await until(ref(isAppLoaded)).toBe(true);

            //     if (to.name !== 'app.login' && !isAuthenticated.value) { 
            //         await autentication.value.HandleRedirectResponse();
            //         intendedRoute.value = to.fullPath; 
            //         next({ name: 'app.login' }); 
            //     }
            //     else next();
            // },
            children: [
                ...standaloneRoutes.map(sr => ({...sr, name: `app.${sr.name}` })),
                {
                    path: 'multiple/:mode?',
                    name: 'app.multiple',
                    component: () => import('../views/MultipleBowTieView.vue'),
                    props: (route) => (route.params.mode ? { mode: route.params.mode } : {}),
                },
                {
                    path: 'dashboard/:dashboardId?',
                    name: 'app.dashboard',
                    component: () => import('../views/DashboardView.vue'),
                    props: (route) => (route.params.dashboardId ? { dashboardId: route.params.dashboardId } : {}),
                },
                {
                    path: 'security',
                    name: 'app.security',
                    component: () => import('../views/SecurityView.vue')
                },
                {
                    path: 'management',
                    name: 'app.management',
                    component: () => import('../components/management/TabLayout.vue'),
                    children: [
                        {
                            path: 'models',
                            name: 'app.management.models',
                            component: () => import('../views/ModelsView.vue')
                        },
                        {
                            path: 'dimensions',
                            name: 'app.management.dimensions',
                            component: () => import('../views/DimensionsView.vue')
                        }
                    ]
                },
                // {
                //     path: 'dimensions',
                //     name: 'app.dimensions',
                //     component: () => import('../views/DimensionsView.vue')
                // },
                // {
                //     path: 'models',
                //     name: 'app.models',
                //     component: () => import('../views/ModelsView.vue')
                // }
            ]
        },
        {
            path: '/app/login',
            name: 'app.login',
            component: () => import('@/layout/AppLogin.vue')
        },
        {
            path: '/',
            name: 'home',
            redirect: { name: 'app.bowtieInfographic' }
        },
        {
            path: '/basic',
            name: 'about',
            component: () => import('../views/BowTieChartView.vue')
        },
        ...standaloneRoutes.map(sr => ({...sr, path: `/${sr.path}` })),
        ...standaloneRoutes.map(sr => ({...sr, path: `/embed/${sr.path}` })),

        {
            path: '/multiple/:mode?',
            name: 'multiple',
            component: () => import('../views/MultipleBowTieView.vue'),
            props: (route) => (route.params.mode ? { mode: route.params.mode } : {}),
        },
        {
            path: '/report/:filePath*',
            name: 'report',
            component: () => import('../views/ReportsViewer.vue'),
            props: (route) => (route.params.filePath ? { filePath: typeof route.params.filePath === 'string' ? route.params.filePath : route.params.filePath.join("/") } : {})
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('../views/DashboardView.vue')
        },
        { path: '/:pathMatch(.*)', redirect: { name: 'home' } }
    ]
});

router.beforeEach(async (to, route, next) => {
    const { autentication, isAuthenticated, intendedRoute, isAppLoaded } = storeToRefs(useAppStore());
    // autentication.value.HandleRedirectResponse();
    await until(isAppLoaded).toBe(true);

    const name = '(Portal)' + ' / ' + route.name?.toString();
    const { appInsights } = useAppInsights();
    appInsights.startTrackPage(name);

    if (to.name !== 'app.login' && !isAuthenticated.value) {
        await autentication.value.HandleRedirectResponse();
        intendedRoute.value = to.fullPath;
        next({ name: 'app.login' });
    }
    else {
        next();
    }
});

router.afterEach(async (to, route) => {
    const name = '(Portal)' + ' / ' + route.name?.toString();
    const url = location.protocol + '//' + location.host + route.fullPath;
    const { appInsights } = useAppInsights();
    appInsights.stopTrackPage(name, url);
    appInsights.flush();
});

export default router
