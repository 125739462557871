export const globalElementWidth = Symbol();
export const globalElementHeight = Symbol();

export const filtersStore = Symbol("bowTie");

export const bowtieChart_filterElementHeight = Symbol();

export const allStoreSymbols = [filtersStore];

export const cardWithSize  = Symbol('cardWithSize');

export const FilterStoreSelectedObject = "fsSelectedObject";

export const globalForInfographic = Symbol();

