<script setup lang="ts">
import { toRef } from '@vueuse/core';
import { createAction } from '@/queries/actions/index';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { BusinessAction, UserData } from '@/apiclient/client';
import FormControl from '@/components/common/FormControl.vue';
import { nextTick, onMounted, ref } from 'vue';
import { SecurityService } from "@/apiclient/services";
import IconTimes from '@/styles/assets/icons/Icons-18.svg';


    const isVisible = defineModel<boolean>('visible', { default: false, required: true });
    const actionModel = defineModel<BusinessAction>('actionModel', { required: true });
    type Props = {
        actionStatus: number, 
        allActions: boolean
    }
    
    const props = defineProps<Props>();
    const showDropdown = ref(false);
    const users = ref<UserData[]>([]);
    const filteredUsers = ref<UserData[]>([]);
    const selectedUsers = ref<string[]>([]);
    const dropdownStyle = ref({ position: 'absolute', top: '0px', left: '0px' });
    const userSelect = ref();


        
    const { mutate: mutateCreateAction } = createAction(toRef(props.actionStatus), toRef(props.allActions));
    const { handleSubmit, setValues, defineField, errors, setErrors, meta, setTouched, values: formValues } = useForm({
        validationSchema: yup.object({
            description: yup.string().required(),
            dueDate: yup.date().required()
        }),
        initialValues: actionModel.value
    });

    const onSave = handleSubmit(async (values) => {
        const updatedValues = {
            ...values,
            status: 'New',
            users: selectedUsers.value,
            owner: 'whatever'
        };
        // setValues(updatedValues);
        // await nextTick();
        mutateCreateAction(BusinessAction.fromJS(updatedValues));
        isVisible.value = false;
    })

    const getCaretCoordinates = (element: any, position: any) => {
        const div = document.createElement('div');
        document.body.appendChild(div);

        const style = getComputedStyle(element);
        div.style.position = 'relative';
        div.style.visibility = 'hidden';
        div.style.whiteSpace = 'pre-wrap';
        div.style.wordWrap = 'break-word';

        for (let prop of style) {
            div.style[prop as any] = style[prop as any];
        }

        div.textContent = element.value.slice(0, position);
        const span = document.createElement('span');
        span.textContent = element.value.slice(position) || '.';
        div.appendChild(span);

        const { top, left } = span.getBoundingClientRect();
        document.body.removeChild(div);

        console.log(top, left);

        return { top: top + window.scrollY, left: left + window.scrollX };
    };

    const handleInput = async (event: any) => {
      const inputValue = event.target.value;
      const atIndex = inputValue.lastIndexOf('@');
      if (atIndex !== -1 && inputValue[atIndex + 1] && !showDropdown.value) {
        showDropdown.value = true;
        filteredUsers.value = users.value.filter(user => user.userName?.toLowerCase().includes(inputValue.slice(atIndex + 1).toLowerCase()));
        await nextTick();
        userSelect.value.show(true);
        userSelect.value.filterValue = inputValue[atIndex + 1];
        await nextTick();
        const userSelectFilterInput = document.querySelector('.p-select-filter') as HTMLInputElement;
        if (userSelectFilterInput) {
            userSelectFilterInput.focus();
        }

        // Get the caret position
        // const textarea = event.target;
        // const { top, left } = getCaretCoordinates(textarea, atIndex + 1);
        // dropdownStyle.value = { position: 'absolute', top: `${top}px`, left: `${left}px` };
        // console.log(dropdownStyle.value);
      } else {
        showDropdown.value = false;
      }
    };

    const handleUserSelect = (event: any) => {
      const selectedUser = event.value;
      const atIndex = formValues?.description?.lastIndexOf('@');
      let newDescription = formValues.description;
      newDescription = newDescription?.slice(0, atIndex) + selectedUser + ' ' + newDescription?.slice(atIndex!, atIndex!);
      setValues({ ...formValues, description: newDescription.replace('@', '[at]') });
      selectedUsers.value.push(selectedUser);
      showDropdown.value = false;
    };

    const removeChip = (user: string) => {
        let foundIndex = selectedUsers.value.findIndex(a => a === user);
        if (foundIndex >= 0) {
            selectedUsers.value.splice(foundIndex, 1);
        }
    }

    onMounted(async () => {
        users.value = await SecurityService.getUsers();
    });

   
</script>
<template>
    <Dialog v-model:visible="isVisible" :style="{ width: '33vw' }" modal :header="'Add new action'">
       <form @submit="onSave">
            <div class="col-12">
                <FormControl  name="description" v-slot="{  fieldValue }">
                    <Textarea v-model="fieldValue.value" @input="handleInput"  autoResize rows="5" class="w-100" required />
                    <template v-for="(user) in selectedUsers" :key="user">
                        <Chip :label="user" :title="user" removable @remove="removeChip(user)">
                        <template #removeicon="{removeCallback}">
                            <IconTimes class="p-icon p-chip-remove-icon flex-shrink-0" @click="removeCallback"/>
                        </template>
                    </Chip>
                    </template>
                    <Select v-if="showDropdown" ref="userSelect" filter :options="filteredUsers" optionLabel="userName" @change="handleUserSelect" appendTo="body" optionValue="userName" class="w-100"
                    :style="dropdownStyle" />
                </FormControl>
            </div>
            <div class="col-12">
                <FormControl  name="dueDate" v-slot="{  fieldValue }">
                    <DatePicker v-model="fieldValue.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" class="w-100" required />
                </FormControl>
            </div>
        </form>
            
        <template #footer>
            <Button type="button" label="Cancel" severity="secondary" @click="isVisible = false" ></Button>
            <Button type="button" label="Save" @click="onSave"></Button>
        </template>
       
    </Dialog>
</template>