/* eslint-disable vue/multi-word-component-names */
import { createApp, type DirectiveArguments, type DirectiveBinding } from 'vue'
import { createPinia } from 'pinia'
import { type PrimeVuePTOptions } from "primevue/config";
import { definePreset } from '@primevue/themes';
// import { palette, $dt } from 'primevue/themes/helpers';
// import * as cfg from 'primevue/themes/config';

import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Ripple from 'primevue/ripple';
import Tooltip from 'primevue/tooltip';

import Select from 'primevue/select';
import ToggleSwitch from 'primevue/toggleswitch';

import TabPanel from 'primevue/tabpanel';

import { VueQueryPlugin } from "@tanstack/vue-query";
import Popover from 'primevue/popover';
import AccordionTab from 'primevue/accordiontab';
import BlockUI, { type BlockUIPassThroughMethodOptions } from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import DatePicker from 'primevue/datepicker';
import Tabs from 'primevue/tabs';
import TabPanels from 'primevue/tabpanels';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';

import { Chart } from 'chart.js';
import {SankeyController, Flow} from 'chartjs-chart-sankey';
import { Form, Field, ErrorMessage } from 'vee-validate';


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Textarea from 'primevue/textarea';
library.add(fas);
dom.watch();

import App from './App.vue'
import router from './router'

import "bootstrap";
import './styles.scss';


const app = createApp(App)
app.use(createPinia());
app.use(router);
// const values1 = palette('#95A4FC');
const CustomPreset = definePreset(Aura, {
    primitive: {
        main: {50: '#fef7f5', 100: '#fadacf', 200: '#f6bda8', 300: '#f3a082', 400: '#ef825b', 500: '#eb6535', 600: '#c8562d', 700: '#a54725', 800: '#81381d', 900: '#5e2815', 950: '#3b190d'},
        darker: { 500: '#E3B9F1' },
        lighter: {  500: '#E6ECF3' },
        subtle: { 500: '#46BB6B' },
        dark1: { 500: '#3A3A3C'},
        dark2: { 500: '#6B7588'},
        dark3: { 500: '#8F90A6'},
        dark4: { 500: '#C7C9D9'},
        light1: { 500: '#F3EFEF'},
        light2: { 500: '#E8EDF4'},
        light3: { 500: '#F2F2F5'},
        light4: { 500: '#FAFAFC'},
        error: { 100: '#ff808059' /* picked from specs*/, 500: '#FF3D3D'},
        warning: { 500: '#F5CC3D' },
        // auto generated using palette helper
        info: {50: '#fafaff', 100: '#9dbff959' /* picked from specs*/, 200: '#d1d8fe', 300: '#bdc7fd', 400: '#a9b5fd', 500: '#95a4fc', 600: '#7f8bd6', 700: '#6873b0', 800: '#525a8b', 900: '#3c4265', 950: '#25293f'},
        success: { 500: '#9FEBB1'  },

        borderRadius: { sm: '3px', md: '4px'}
    },
    semantic: {
        primary: {
            50: '{main.50}',
            100: '{main.100}',
            200: '{main.200}',
            300: '{main.300}',
            400: '{main.400}',
            500: '{main.500}',
            600: '{main.600}',
            700: '{main.700}',
            800: '{main.800}',
            900: '{main.900}',
            950: '{main.950}'
        },
        focusRing: {
            width: 0,
        },
        colorScheme:{ 
            light: {
                formField: {
                    icon: { color: '{main.500}'}
                }
            }
        },
        icon: {
            size: '16px',
            small: '1rem',
            medium: '16px',
            large: '18px'
        }
    },
    components: {
        toggleswitch: {
            root: {
                borderColor: '{main.500}'
            },
            colorScheme: {
                light: {
                  root: {
                    background: 'transparent',
                    hoverBackground: '{primary.100}',
                    checkedBackground: '{primary.color}',
                    checkedHoverBackground: '{primary.hover.color}'
                  },
                  handle: {
                    background: '{primary.color}',
                    hoverBackground: '{primary.color}',
                    checkedBackground: '{surface.0}',
                    checkedHoverBackground: '{surface.0}'
                  }
                }
            }
        },
        tabs: {
            tab:{
                padding: '0.5rem',
                active: {
                    background: '{primary.color}',
                    color: '{surface.0}'
                },
                hover: {
                    background: '{light1.500}',
                    color: '{primary.color}',
                    borderColor: '{primary.color}'
                }
            }
        },
        datatable:{
            headerCell: {
                background: '{lighter.500}'
            }
        },
        button: {
            root: {
                paddingX: '1rem',
                paddingY: '0.5rem'
            },
            colorScheme: {
                light: {
                    root: {
                        primary: {
                            hoverBackground: "{primary.contrast.color}", // white
                            activeBackground: "{primary.color}",
                            borderColor: "{primary.color}",
                            hoverBorderColor: "{primary.color}",
                            activeBorderColor: "{primary.color}",
                            color: "{primary.contrast.color}",
                            hoverColor: "{primary.color}",
                        },
                        secondary: {
                            "background": "{primary.contrast.color}",
                            "hoverBackground": "{primary.contrast.color}",
                            "activeBackground": "{primary.100}",
                            "borderColor": "{dark1.500}",
                            "hoverBorderColor": "{primary.color}",
                            "activeBorderColor": "primary.100",
                            "color": "{text.color}",
                            "hoverColor": "{primary.color}",
                            "activeColor": "{surface.800}",
                        }
                    },
                    text: {
                        primary: {
                            "color": "{dark1.500}",
                            hover: {
                                "color": "{primary.contrast.color}",
                                background: "{primary.color}"
                            },
                            active: {
                                borderColor: "{dark1.500}",
                                "background": "{primary.contrast.color}",
                            }
                        }, 
                        warn : {
                            "color": "{primary.color}",
                            hover: {
                                "color": "{primary.contrast.color}",
                                background: "{primary.color}"
                            },
                            active: {
                                borderColor: "{dark1.500}",
                                "background": "{primary.contrast.color}",
                            }
                        }
                    },
                    md: {
                        fontSize: '{icon.medium}'
                    },
                    lg: {
                        fontSize: '{icon.large}'
                    },
                    sm: {
                        fontSize: '{icon.small}'
                    }
                }
            }
        },
        chip: {
            root: {
                borderRadius: "{form.field.border.radius}",
                paddingY: 'calc({form.field.paddingY} + 1px)'
            },
            removeIcon: {
                size: '{icon.large}',
            },
            colorScheme: {
                light: {
                    removeIcon: {
                        color: '{primary.color}'
                    }
                }
            }
        },
        fieldset: {
            colorScheme:{
                light: {
                    borderColor: '{primary.color}',
                    color: '{primary.color}',
                    legend: {
                        borderColor: '{primary.color}',
                        hover: {
                            background: "{primary.contrast.color}",
                            color: "{primary.contrast.color}",
                        }
                    },
                    toggleIcon: {
                        color: '{primary.color}',
                        hover: {
                            color: "{primary.color}",
                        }
                    }
                }
            }
        },
        progressspinner: {
            colorScheme: {
                light: {
                    color: {
                        1: "{primary.color}",
                        2: "{primary.color}",
                        3: "{primary.color}",
                        4: "{primary.color}",
                    }
                }
            }
        }
    }
});
app.use(PrimeVue, { 
    ripple: true,
    pt: {
        divider: {
            // content: 'bg-transparent'
        },
        progressspinner: {
            root: {
                style: `z-index: 5000; position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;`
            }  
        },
        blockui: {
            root: ({ props, state, instance, parent }: BlockUIPassThroughMethodOptions) => {
                return {
                    class: 'h-100 w-100',
                    style: !props.fullScreen && `min-height: 200px;`
                }
            }
        },
        tablist: {
            tabs: {
                style: `gap: 1rem;`
            }  
        },
        tab: {
            root: {
                style: 'border-top-left-radius: 5px;border-top-right-radius: 5px; padding-top: 0; padding-bottom: 0;',
                // class: 'hover:border-primary'
            }
        },
        chip: {
            root: { style: "max-width: 150px" },
            label: "white-space-nowrap overflow-hidden text-overflow-ellipsis flex-grow-1",
            removeIcon: "flex-shrink-0"
        },
        fieldset:{
            toggleButton: 'flex-row-reverse text-primary'
        },
        // "icon" not used, example only        
        button:{
            icon(options: any) {
                if(options.props.text) {
                    return {
                        onmouseenter: () => {} // on hover
                    }
                }
            },
        }
    } as PrimeVuePTOptions,
    theme: {
        preset: CustomPreset,
        options: {
            prefix: 'p',
            darkModeSelector: '.dark-not-implemented',
            cssLayer: { order: 'bootstrap, primeflex, primeui', name: 'primeui'}
        }
    } 
});

Chart.register(SankeyController, Flow);
// const primary = $dt('primary.hover.color');

// app.use(PrimeVue, { ripple: true, unstyled: true, pt: Bootstrap_PT  });
app.use(ToastService);
app.use(ConfirmationService);
app.use(DialogService);
app.directive('ripple', Ripple);
app.directive('tooltip', Tooltip);


app.component("Select", Select);
app.component("ToggleSwitch", ToggleSwitch);
/* needs refactoring */
app.component("Tabs", Tabs);
app.component("Tab", Tab);

app.component("TabPanels", TabPanels);
app.component("TabList", TabList);
app.component("TabPanel", TabPanel);

app.component("Popover", Popover);
app.component("DatePicker", DatePicker);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Toast", Toast);


/* needs refactoring */
app.component("AccordionTab", AccordionTab);

app.component('FontAwesomeIcon', FontAwesomeIcon);
// const preset = cfg.default.getPreset();
app.use(VueQueryPlugin);

app.component("Form", Form);
app.component("Field", Field);
app.component("ErrorMessage", ErrorMessage);
app.component("Textarea", Textarea);

app.directive('custom-format', (el: HTMLElement, binding: DirectiveBinding) => {
    const value = binding.value;
    if(binding.arg === 'number') {
        el.textContent = isNaN(value) || value === Infinity ? 'N/A' : value?.toLocaleString('en-US', { maximumFractionDigits: 0 });
    }

    if(binding.arg === 'percentage') {
        el.textContent = (isNaN(value) || value === Infinity ? 'N/A' : ((value * 100).toLocaleString('en-US', { maximumFractionDigits: 2 })) + '%');
    }
});

app.mount('#app');

