import { useAppStore } from '@/stores/app';
import { defineStore, storeToRefs } from 'pinia';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export const useAppInsights = defineStore('appInsights', () => {

    const appInsights: ApplicationInsights = new ApplicationInsights({
        config: {
            connectionString: 'InstrumentationKey=7b30f170-a5ea-4906-8efd-acd2165bffd4;IngestionEndpoint=https://canadacentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/;ApplicationId=3950f01a-99d9-4897-9108-01c7df871377'
        }
    });
    appInsights.loadAppInsights();

    const telemetryInitializer = (envelope: any) => {
        const { autentication } = storeToRefs(useAppStore());
        if (envelope.data) {
            envelope.data["UserName"] = autentication?.value?.userDetails?.userName;
            envelope.data["Tenant"] = autentication?.value?.userDetails?.tenant;
        }
    };
    appInsights.addTelemetryInitializer(telemetryInitializer);

    return { appInsights }
})



//appInsights.trackEvent({ name: 'some event' });
//appInsights.trackPageView({ name: 'some page' });
//appInsights.trackPageViewPerformance({ name: 'some page', url: 'some url' });
//appInsights.trackException({ exception: new Error('some error') });
//appInsights.trackTrace({ message: 'some trace' });
//appInsights.trackMetric({ name: 'some metric', average: 42 });
//appInsights.trackDependencyData({ absoluteUrl: 'some url', responseCode: 200, method: 'GET', id: 'some id' });
//appInsights.startTrackPage("pageName");
//appInsights.stopTrackPage("pageName", null, { customePropertiesName: "some value" }, { customerMeasurementsName: 144 });
//appInsights.startTrackEvent("event");
//appInsights.stopTrackEvent("event", null, { customePropertiesName: "some value" }, { customerMeasurementsName: 150 });
//appInsights.flush();