<script setup lang="ts">
    import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
    import type { Ref } from 'vue';
    import { useLayout } from '@/layout/composables/layout';
    import { useRouter } from 'vue-router';
    import { storeToRefs } from 'pinia'
    import { useAppStore } from '@/stores/app';
    import { type AccountInfo } from '@azure/msal-browser';
    import Avatar from 'primevue/avatar';
    import Popover from 'primevue/popover';
import Dropdown, { type DropdownChangeEvent } from 'primevue/dropdown';
import type { UserTenant } from '@/apiclient/client';


    const { autentication, accounts, tenants } = storeToRefs(useAppStore());
    const { layoutConfig, onMenuToggle } = useLayout();

    const outsideClickListener = ref();
    const topbarMenuActive = ref(false);
    const router = useRouter();
    const actionsSidebarVisible = ref(false);

    const avatar: Ref<string | undefined> = ref(undefined);
    const initials: Ref<string | undefined> = ref();


    onMounted(async () => {
        bindOutsideClickListener();
        initials.value = autentication.value.UserInitials();
        avatar.value = await autentication.value.UserAvatar();
        // accounts.value = await autentication.value.GetAllAccounts() ?? [];
    });

    onBeforeUnmount(() => {
        unbindOutsideClickListener();
    });

    const logoUrl = computed(() => {
        return `/images/una_logo_white_text.png`;
    });

    const onTopBarMenuButton = () => {
        topbarMenuActive.value = !topbarMenuActive.value;
    };
    const onSettingsClick = () => {
        topbarMenuActive.value = false;
        router.push('/documentation');
    };
    const topbarMenuClasses = computed(() => {
        return {
            'layout-topbar-menu-mobile-active': topbarMenuActive.value
        };
    });

    const bindOutsideClickListener = () => {
        if (!outsideClickListener.value) {
            outsideClickListener.value = (event: any) => {
                if (isOutsideClicked(event)) {
                    topbarMenuActive.value = false;
                }
            };
            document.addEventListener('click', outsideClickListener.value);
        }
    };
    const unbindOutsideClickListener = () => {
        if (outsideClickListener.value) {
            document.removeEventListener('click', outsideClickListener as any);
            outsideClickListener.value = null;
        }
    };
    const isOutsideClicked = (event: { target: Node | null; }) => {
        if (!topbarMenuActive.value) return;

        const sidebarEl = document.querySelector('.layout-topbar-menu');
        const topbarEl = document.querySelector('.layout-topbar-menu-button');

        return !(sidebarEl?.isSameNode(event.target) || sidebarEl?.contains(event.target) || topbarEl?.isSameNode(event.target) || topbarEl?.contains(event.target));
    };

    const avatarPanel = ref<InstanceType<typeof Popover> | null>(null);

    function OpenAvatarPanel(event: Event) {
        avatarPanel.value?.toggle(event);
    }

    async function SignOut() {
        await autentication.value.SignOut();
    }

    async function SignIn() {
        await autentication.value.SignIn();
    }

    async function selectAccount(account: AccountInfo) {
        autentication.value.account = account;
        window.location.reload();
        // autentication.value.tenant = null;
        // autentication.value.userDetails = null;
    }
    const onTenantChange = (event: DropdownChangeEvent) => {
        localStorage.setItem('selected-tenant', event.value);
        window.location.reload();
    }

    const openActionsSidebar = () => {

    }
</script>

<template>
    <div class="layout-topbar">

        <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
            <i class="pi pi-ellipsis-v"></i>
        </button>

        <div class="layout-topbar-menu" :class="topbarMenuClasses">
            <Button v-if="false" :label="undefined" :severity="'secondary'" icon-only="true"  icon-pos="right" @click="() => actionsSidebarVisible = true">
                <template #icon>
                    <CustomIcons class="p-button-icon-right" :iconName="'Icon-32'" style="height: 15px" text ></CustomIcons>
                </template>
            </Button>
            <div style="width:20px"></div>
            <Select :model-value="autentication.tenant" @change="onTenantChange" :options="tenants" optionLabel="tenant" option-value="tenant" class="w-14rem form-select h-100 m-auto"></Select>
            <div style="width:20px"></div>
            <Avatar v-if="avatar!=null" class="mr-2" size="large" :image="avatar" @click="OpenAvatarPanel" shape="circle"  style="cursor:pointer" />
            <Avatar v-else :label="autentication.UserInitials()" class="mr-2" size="large" @click="OpenAvatarPanel" shape="circle" style="color:black; cursor:pointer;"   />
            <Popover ref="avatarPanel" class="popover-no-arrow">
                <div style="display:grid; grid-template-columns:auto 1fr auto; margin-bottom:20px;">
                    <div>{{autentication.userDetails?.tenant}}</div>
                    <div></div>
                    <div><a href="#" @click="SignOut">Log Out</a></div>
                </div>
               
                <div style="display:grid; grid-template-columns:auto 1fr; margin-bottom:10px; grid-column-gap:12px;">
                    <Avatar v-if="avatar!=null" class="mr-2" size="xlarge" :image="avatar" shape="circle" />
                    <Avatar v-else :label="autentication.UserInitials()" class="mr-2" size="xlarge" shape="circle" style="color:red" />
                    <div>
                        <div><strong>{{autentication.userDetails?.name}}</strong></div>
                        <div>{{autentication.userDetails?.userName}}</div>
                    </div>
                </div>
                <div>
                    <div><b>Switch Account</b></div>
                    <div v-for="account in accounts" :key="account.localAccountId" @click="selectAccount(account)" style="cursor:pointer;">
                        <div v-if="account.username != autentication.userDetails?.userName" >
                           {{account.name}} - {{account.username}}
                        </div>
                    </div>
                    <div style="text-align:right;">
                        <a href="#" @click="SignIn">Sign in with a different account</a>
                    </div>
                </div>

            </Popover>
            <ActionsSidebar v-model:visible="actionsSidebarVisible"></ActionsSidebar>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    // .layout-topbar {
    //     background-color: #eb6535;
    // }
</style>
